import React, { useState, useRef, useEffect } from 'react'
import '../assets/css/Login.css'
import '../assets/css/styleguide.css'
import '../assets/css/global.css'
import frame1 from '../assets/css/img/login/frame-1.svg'
import frame from '../assets/css/img/login/frame.png'
import frame2 from '../assets/css/img/login/frame-2.svg'
import frame3 from '../assets/css/img/login/frame-3.svg'
// import state from '../assets/css/img/login/states.png'
// import trailing from '../assets/css/img/login/trailing-icon.png'
// import trailing1 from '../assets/css/img/login/trailing-icon-1.png'
import line81 from '../assets/css/img/login/line-8-1.svg'
import line8 from '../assets/css/img/login/line-8.svg'
import linkedin from '../assets/css/img/login/linkedin.png'
import microsoft from '../assets/css/img/login/google-logo-1.png'
import google from '../assets/css/img/login/google-logo.png'
import group4 from '../assets/css/img/login/group-4.png'
import whatsnew from '../assets/css/img/login/whats-new.png'
import help from '../assets/css/img/login/help.png'
// import website1 from '../assets/css/img/login/icons8-website-1.png'
import news from '../assets/css/img/login/icons8-news-1-1.png'
import videotutorial from '../assets/css/img/login/video-tutorials.png'
import img2 from '../assets/css/img/login/img.png'
import { TextField, alertTitleClasses } from '@mui/material';
// import { ThemeContext } from '@mui/system';
import { Checkbox } from '@mui/material';
import { useMediaQuery } from "react-responsive";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MicIcon from "@mui/icons-material/Mic";
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

import Tick from "../assets/images/Tick-svg.svg"
import Warning from "../assets/images/warning Sysmbol.svg"
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import Sheet from '@mui/joy/Sheet';
import { gapi } from 'gapi-script'

import {
  FormControl,
  FormHelperText,
  DialogContentText,
  InputAdornment,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';

import { GoogleLogin } from 'react-google-login';

import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import Captcha from './Captcha'

import QrLogin from './QrLogin'
import { v4 as uuidv4 } from 'uuid';

const LoginNewTry = ({ showCaptcha }) => {

  const apiKey = process.env.REACT_APP_API_KEY; // actual API key
  const headers = {
    'x-api-key': apiKey,
    'Content-Type': 'application/json'
  };

  const isSmallScreen = useMediaQuery({ maxWidth: 1199 });
  const isHamburger = useMediaQuery({ maxWidth: 992 });
  const [showPassword, setShowPassword] = useState(false);
  const [loginType, setloginType] = useState("web");

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };


  /* Speech Recognition*/
  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const [micActive, setMicActive] = useState(false); // State for microphone activation
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const [micBlinkFirstName, setMicBlinkFirstName] = useState(false); // State for mic blinking (first name)
  const [micBlinkLastName, setMicBlinkLastName] = useState(false); // State for mic blinking (last name)
  const [micBlink, setMicBlink] = useState(false);
  const [Email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const storedRememberMe = localStorage.getItem('rememberMe') === 'true';
  const [rememberMe, setRememberMe] = useState(storedRememberMe);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [registeredLoginId, setRegisteredLoginId] = useState({
    email: ''
  });
  const [FirstPageForgotPassword, setFirstPageForgotPassword] = useState(true);
  const [SecondPageForgotPassword, setSecondPageForgotPassword] = useState(false);
  const [ThirdPageForgotPassword, setThirdPageForgotPassword] = useState(false);
  const otpInputsRef = useRef([]);
  const [otpValues, setOtpValues] = useState(['', '', '', '']);
  const [objectId, setobjectId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sessionId, setSessionId] = useState(uuidv4()); // To send sessionID
  const [responseToken, setResponseToken] = useState(''); // To track the generated Token
  const [isVerified, setIsVerified] = useState(false); // Track if the token is verified
  const [isExpired, setIsExpired] = useState(false); // For any errors or expiry notice

  const handleRememberChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleTermsChange = () => {
    setTermsAgreed(!termsAgreed);
  };
  // Function to store login credentials
  const storeLoginCredentials = () => {
    if (rememberMe) {
      localStorage.setItem('email', Email);
      localStorage.setItem('password', password);
    }
    else {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
    localStorage.setItem('rememberMe', rememberMe);

  };


  const handleMicClick = (fieldRef, setMicBlink) => {
    console.log("Mic clicked");
    setMicActive(true); // Activate the microphone
    fieldRef.current.focus(); // Focus on the text field
    setMicBlink(true);
    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = ["en-US"];

    const onFocusChange = () => {
      if (document.activeElement !== fieldRef.current) {
        recognition.stop();
        setMicActive(false);
        setMicBlink(false);
      }
    };
    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");

      // Update the appropriate text field based on the active element
      if (document.activeElement) {
        const activeElementId = document.activeElement.id;
        if (activeElementId === "neoinId") {
          setEmail(transcript);
        }
      }
    };

    recognition.onend = () => {
      setMicBlink(false); // Stop blinking when speech recognition ends
    };

    fieldRef.current.addEventListener("blur", onFocusChange);
    recognition.start();
  };

  const [isDialogBoxOpen, setisDialofBoxOpen] = useState(false);
  const [isForgotPassword, setisForgotPassword] = useState(false);

  const [validSubmitForgotPass, setvalidSubmitForgotPass] = useState(false);

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    // alert("Clicked");
    setvalidSubmitForgotPass(true);
    try {
      if (registeredLoginId.email.trim() !== "") {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/users/send-forgot-otp`,
          registeredLoginId,
          {
            headers: headers
          }
        );
        console.log("Response:", response.data);

        if (response.status === 200) {
          setFirstPageForgotPassword(false);
          setSecondPageForgotPassword(true);
          setMinutes(0);
          setSeconds(60);
        } else if (response.status === 204) {
          alert('User does not exist for this email ID');
          handlecloseDialog();
        } else {
          alert('An unexpected error occurred');
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }

  const handleClickForgotPassword = () => {
    console.log("Forgot Password Clicked");
    setRegisteredLoginId('');
    setCode('');
    setOtpValues(['', '', '', '']);
    setisForgotPassword(true);
    setFirstPageForgotPassword(true);
  };

  const handlecloseDialog = () => {
    setRegisteredLoginId('');
    setCode('');
    setOtpValues(['', '', '', '']);
    setNewPassword('');
    setConfirmPassword('');
    setisForgotPassword(false);
    setFirstPageForgotPassword(false);
    setSecondPageForgotPassword(false);
    setThirdPageForgotPassword(false);
    setisDialofBoxOpen(false);
    setSelectedOption(null);
  }

  const [TwoFactorAuthFirstPage, setTwoFactorAuthFirstPage] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCode, setShowCode] = useState(true);
  const [code, setCode] = useState('');
  const [showCodeError, setShowCodeError] = useState(false);
  const [TwoFactorAuthSecondPage, setTwoFactorAuthSecondPage] = useState(false);
  const [isVerifiedDialogOpen, setIsVerifiedDialogOpen] = useState(false);
  const [isPageNotVerified, setIsPageNotVerified] = useState(false);

  const handleLogin = async (e) => {
    setCode('');
    setErrorMessage('');
    setOtpValues(['', '', '', '']);
    setSelectedOption(null);
    e.preventDefault();
    if (termsAgreed) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/users/login`,
          {
            email: Email,
            password: password,
            loginMetho: "Mobile",
            loginType: loginType,
          },
          {
            headers: headers
          }
        );

        if (response.status === 200) {
          console.log("set if", response);
          // alert(response.data.user._id)
          // Asynchronously set objectId
          if (!response.data.data.accessToken) {
            alert("Error: objectId is not set properly. Please try again.");
            return; // Exit the function
          }
          await setobjectId(response.data.data.accessToken);
          await localStorage.setItem('objectId', response.data.data.accessToken);
          // Check if objectId is set properly


          setMobileNumber(response.data.user.phone_no);
          setisDialofBoxOpen(true);
          setTwoFactorAuthFirstPage(true);
          setTwoFactorAuthSecondPage(false);
          console.log("resp data", response.data);
          console.log(mobileNumber);
          console.log('Login successful:', response.data);

          // Save token to local storage and redirect to dashboard
        } else if (response.status === 401) {
          alert("Email Id or Password is incorrect");
        }
      } catch (error) {
        console.error('Login failed:', error.response.data.message);
      }
    } else {
      alert("Please agree to the terms and conditions.");
    }
  };

  const handleCloseVerifiedDialog = () => {
    setIsVerifiedDialogOpen(false);
    setIsPageNotVerified(false);
    // setVerificationPageVisible(false);
    //? setShowProfessionalContent(true); Show professional content after closing the dialog
  };
  const handleContinue2 = () => {
    setIsVerifiedDialogOpen(false);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
    setShowCodeError(false);
  };

  const toggleCodeVisibility = () => {
    setShowCode(prev => !prev);
  };

  const handleCodeSubmit = () => {
    // setisDialofBoxOpen(false);
    // setTwoFactorAuthSecondPage(false);
    if (code.trim() === '') {
      setShowCodeError(true);
      return;
    }

    if (selectedOption === "email") {
      // Make API call to verify OTP
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/onboard/twofactor-verify-otp`,
        {
          email: `${Email}`,
          otp: code
        },
        {
          headers: headers
        }
      )
        .then(response => {
          setErrorMessage('');
          console.log("OTP verified successfully");
          setisDialofBoxOpen(false);
          setTwoFactorAuthSecondPage(false);
          setIsVerifiedDialogOpen(true);
        })
        .catch(error => {
          // setIsPageNotVerified(true);
          setErrorMessage('Incorrect OTP. Please Retry.')
          console.error("Failed to verify OTP:", error);
        });
    } else {
      // Make API call to verify OTP via mobile
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/onboard/twofactorverify-mobile-otp`,
        {
          mobileNumber: `${mobileNumber}`,
          otp: parseInt(code)
        },
        {
          headers: headers
        }
      )
        .then(response => {
          setErrorMessage('');
          console.log("Mobile OTP verified successfully");
          setisDialofBoxOpen(false);
          setTwoFactorAuthSecondPage(false);
          setIsVerifiedDialogOpen(true);
        })
        .catch(error => {
          // setIsPageNotVerified(true);
          setErrorMessage("Incorrect OTP. Please Retry.");
          console.error("Failed to verify mobile OTP:", error);
        });
    }
  }

  const [validNextFor2FA, setvalidNextFor2FA] = useState(false);

  const handleOptionChange = (event) => {
    setvalidNextFor2FA(false);
    setSelectedOption(event.target.value);
  };

  const handleAuthNextPage = () => {
    console.log(mobileNumber);
    setvalidNextFor2FA(true);
    if (selectedOption === "email") {
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/onboard/twofactor-send-otp`,
        {
          email: `${Email}`
        },
        {
          headers: headers
        }
      )
        .then(response => {
          setTwoFactorAuthFirstPage(false);
          setTwoFactorAuthSecondPage(true);
          setMinutes(0);
          setSeconds(60);
          console.log("OTP sent successfully");
        })
        .catch(error => {
          // Handle error, such as displaying an error message to the user
          console.error("Failed to send OTP:", error);
        });
    }
    else {
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/onboard/twofactorsend-mobile-otp`,
        {
          mobileNumber: `${mobileNumber}`
        },
        {
          headers: headers
        }
      )
        .then(response => {
          setTwoFactorAuthFirstPage(false);
          setTwoFactorAuthSecondPage(true);
          setMinutes(0);
          setSeconds(60);
          console.log("Mobile OTP sent successfully");
        })
        .catch(error => {
          console.error("Failed to send mobile OTP:", error);
        });
    }
  }

  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const passwordsMatch = newPassword === confirmPassword;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  const [ShowPasswordError, setShowPasswordError] = useState(false);

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  /* timer */
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0); // Change initial seconds value to 0

  const resendOTP = async () => {
    setMinutes(0);
    setSeconds(60);
    if (selectedOption === "email") {
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/onboard/twofactor-send-otp`,
        {
          email: `${Email}`
        },
        {
          headers: headers
        }
      )
        .then(response => {
          console.log("OTP sent successfully");
        })
        .catch(error => {
          // Handle error, such as displaying an error message to the user
          console.error("Failed to send OTP:", error);
        });
    }
    else {
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/onboard/twofactorsend-mobile-otp`,
        {
          mobileNumber: `${mobileNumber}`
        },
        {
          headers: headers
        }
      )
        .then(response => {
          console.log("Mobile OTP sent successfully");
        })
        .catch(error => {
          console.error("Failed to send mobile OTP:", error);
        });
    }

  };

  useEffect(() => {
    const storedUsername = localStorage.getItem('email');
    const storedPassword = localStorage.getItem('password');
    if (storedUsername && storedPassword) {
      setEmail(storedUsername);
      setPassword(storedPassword);
    }

    const sendMobileToken = async () => {
      if (!sessionId) {
        const newUuid = uuidv4();
        setSessionId(newUuid);
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/api/generate-token`, {
          method: 'POST',
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId: sessionId }), // Convert sessionId to JSON
        });

        if (!response.ok) {
          throw new Error('Failed to generate token');
        }

        const responseData = await response.json();

        if (responseData.status === 'SUCCESS') {
          const tokenResponse = responseData.data.token;
          console.log('responseToken:', tokenResponse);
          setResponseToken(tokenResponse);
        } else {
          console.error('Error in response: ', responseData.message);
        }
      } catch (error) {
        console.log('Error generating Token in Login', error);
      }
    };

    sendMobileToken();
  }, []);

  // Function to poll for token status
  const checkTokenStatus = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/api/check-token-status/${token}`, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        }
      });

      const responseData = await response.json();

      if (responseData.status === 'SUCCESS' && responseData.data.verified) {
        console.log("accessToken received:", responseData.data.accessToken);
        setobjectId(responseData.data.accessToken);
        // localStorage.setItem('objectId', responseData.data.accessToken);
        setIsVerified(true); // Set verified status
        // Wait for the objectId to be set and then call handleContinuetoDigital
        setTimeout(() => {
          handleContinuetoDigital(); // Call the redirection function
        }, 0); // Ensure it runs after the objectId is set
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error checking token status:', error);
    }
  };

  useEffect(() => {
    if (responseToken) {
      const pollInterval = 5000; // Poll every 5 seconds
      const expirationTime = 5 * 60 * 1000; // Token valid for 5 minutes
      let pollTimeout = null;

      // Function to poll for token status
      const pollTokenStatus = () => {
        if (!isExpired) {  // Poll only if token is not expired and not verified yet
          checkTokenStatus(responseToken)
            .then((verified) => {
              // if (verified) {
              //   console.log('Token verified, redirecting to Home page...');
              //   handleContinuetoDigital(); // Redirect when verified
              // } else
               if (!verified) {
                // Continue polling if not verified and not expired
                pollTimeout = setTimeout(pollTokenStatus, pollInterval);
              }
            })
            .catch((error) => {
              console.log('Error polling responseToken', error);
            });
        }
      };

      // Start polling immediately
      pollTokenStatus();

      // Stop polling after the token expires
      const expirationTimer = setTimeout(() => {
        setIsExpired(true);
        setResponseToken(null); // Clear the token after expiration
        clearTimeout(pollTimeout); // Ensure polling stops once expired
      }, expirationTime);

      // Cleanup timeout on component unmount
      return () => {
        clearTimeout(expirationTimer);
        clearTimeout(pollTimeout); // Cleanup polling timeout as well
      };
    }
  }, [responseToken, isExpired, isVerified]); // Ensure useEffect runs on isVerified changes  

  useEffect(() => {
    storeLoginCredentials();
  }, [Email, password, rememberMe]); // Trigger the effect when relevant data changes



  useEffect(() => {
    if (TwoFactorAuthSecondPage || SecondPageForgotPassword) {
      // Start the timer only when TwoFactorAuthSecondPage becomes true
      const interval = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          setOtpValues(['', '', '', '']);
          setCode('');

          clearInterval(interval);

        } else {
          // Decrease seconds if greater than 0
          if (seconds > 0) {
            setSeconds(seconds - 1);
          } else {
            if (minutes > 0) {
              setMinutes(minutes - 1);
              setSeconds(59); // Reset seconds to 59 when minutes decrease
            }
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [SecondPageForgotPassword, TwoFactorAuthSecondPage, seconds, minutes,]);

  const handleInput = (index) => (e) => {
    setValidVerifyCode(false);
    const value = e.target.value;
    const maxLength = parseInt(e.target.getAttribute("maxlength"));
    const nextInput = otpInputsRef.current[index + 1];

    // Update the OTP values in the state array
    setOtpValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = value;
      return newValues;
    });

    if (value.length === maxLength && nextInput) {
      nextInput.focus();
    }
  };

  const [validVerifyCode, setValidVerifyCode] = useState(false);

  const handleCodeSubmitPassword = async () => {
    const otp = parseInt(otpValues.join('')); // Concatenate OTP values and convert to number
    setValidVerifyCode(true);
    // alert(otp);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/users/verify-forgot-otp`,
        {
          email: registeredLoginId,
          otp: otp
        },
        {
          headers: headers
        }
      );

      console.log(response.data.message);
      setSecondPageForgotPassword(false);
      setThirdPageForgotPassword(true);
    }
    catch (error) {
      console.error('Error verifying email:', error);
      alert('Error verifying email. Please try again later.');
    }
  }

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setShowPasswordError(true);
      return;
    }
    else {
      setThirdPageForgotPassword(false);
      setisForgotPassword(false);

      // setIsSubmitted(false);
      try {
        setEmail(registeredLoginId.email);
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/onboard/onboarding/update-password`,
          {
            newPassword: newPassword,
            email: registeredLoginId.email,
          },
          {
            headers: headers
          }
        );

        console.log("res of pass", response);
        if (response.status === 200) {
          // setobjectId(response.data.record._id);
          // await localStorage.setItem('objectId', response.data.record._id);
          // setMobileNumber(response.data.record.phoneNo);
          setTwoFactorAuthFirstPage(true);
          setisDialofBoxOpen(true);
          handlecloseDialog();
        }
        else {
          alert("record not found ");
        }

      } catch (error) {
        console.error('Error updating password:', error);
      }
    }
  }

  const handleContinuetoDigital = async () => {
    setCode('');
    setOtpValues(['', '', '', '']);
    setSelectedOption(null);
    console.log("Object ID: " + objectId);


    if (objectId) {
      try {
        // Construct a URL object
        const url = new URL("https://neoin-onboard.azurewebsites.net/home");

        // Append objectId as a query parameter to the URL object
        url.searchParams.append("objectId", objectId);

        // Redirect to the modified URL
        window.location.href = url.href;
      } catch (error) {
        console.error("Error setting objectId to URL parameter:", error);
        alert("Something went wrong");
      }
    } else {
      // alert("objectId is missing");
      console.error("objectId is missing")
    }
  }




  const clientId = '60482953964-k5t7rkog4njbglmkeknkogt35epjiujd.apps.googleusercontent.com';
  // const clientId = '728557044719-oublf7ucnfmv0kphlhta9nhnl7ff0l3r.apps.googleusercontent.com';

  const handleGoogleLogin = () => {
    console.log("google sujccess");
  };
  const onSuccess = async (googleUser) => {
    console.log('Login Success:', googleUser.profileObj.email);
    if (googleUser.profileObj.email) {
      console.log("Email Id 1 is", googleUser.profileObj.email); // Corrected Email access
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/mail/mailid/userDetails`, { email: googleUser.profileObj.email },
          { headers: headers }
        ); // Corrected Email access    
        console.log(response);
        if (response.status === 200) {
          // Extract phone number from response data
          const phoneNo = response.data.phoneNo.phone_no;
          console.log("objid", response.data.accessToken);
          console.log("PhoneNo is", phoneNo);
          console.log("Email id 2 is ", googleUser.profileObj.email)
          setobjectId(response.data.accessToken);
          await localStorage.setItem('objectId', response.data.accessToken);
          setMobileNumber(phoneNo);
          setEmail(googleUser.profileObj.email);
          setTwoFactorAuthFirstPage(true);
          setisDialofBoxOpen(true);
        }
        else if (response.status === 204) {
          alert("Email id is not exist")

        }
        else {
          // Throw an error if the response is not successful
          throw new Error('Failed to fetch user details');
        }
      } catch (error) {
        // Handle any errors
        console.error('Error fetching user details:', error);
        return null;
      }

    }
  };


  const onFailure = (error) => {
    console.log('Login Failed:', error);
    // Handle failed login here
  };

  const [account, setAccount] = useState(null);
  const [msalApp, setMsalApp] = useState(null); // State for MSAL instance
  const [initialized, setInitialized] = useState(false); // State to track initialization status

  const config = {
    auth: {
      clientId: 'f14a76e6-f7bd-4625-b163-b5af7380d99a',
      authority: 'https://login.microsoftonline.com/f5e0a6ca-7559-4bef-ac3c-196e797e4e50',
      redirectUri: 'https://neoin.co/login_new',
    },
  };

  useEffect(() => {
    const initializeMsal = async () => {
      const app = new PublicClientApplication(config);
      setMsalApp(app);

      try {
        // Call the initialize function before attempting to call any other MSAL API
        await app.initialize();
        await app.handleRedirectPromise();
        const accounts = await app.getAllAccounts();
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          console.log("account of micro", accounts[0]);
        }
        setInitialized(true); // Set initialization status to true once completed
      } catch (error) {
        console.error('Failed to initialize MSAL app:', error);
      }
    };

    initializeMsal();
  }, []); // Empty dependency array ensures this runs only once

  const handleLoginmicro = async () => {
    if (!initialized || !msalApp) {
      console.error('MSAL app is not initialized.');
      return;
    }

    try {
      await msalApp.loginPopup({
        scopes: ['user.read'],
        prompt: 'select_account',
        interactionType: InteractionType.Popup,
      });
      const accounts = await msalApp.getAllAccounts();
      console.log("accounts of micro", accounts);


      if (accounts.length > 0) {
        // setEmail(accounts[0].username);
        setEmail(accounts[0].username.toLowerCase());
        console.log("Email Id is", Email);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/mail/mailid/userDetails`,
            { email: accounts[0].username.toLowerCase() },
            {
              headers: headers
            }
          );
          console.log(response);
          if (response.status === 200) {
            // Extract phone number from response data
            const phoneNo = response.data.phoneNo.phoneNo;
            console.log("obg micro", response.data.phoneNo._id);
            console.log("PhoneNo is", phoneNo);
            setMobileNumber(phoneNo)
            setobjectId(response.data.phoneNo._id);
            await localStorage.setItem('objectId', response.data.phoneNo._id);
            setTwoFactorAuthFirstPage(true);
            setisDialofBoxOpen(true);
          }
          else if (response.status === 204) {

            alert("Email id is not exist")
          }
          else {
            // Throw an error if the response is not successful
            throw new Error('Failed to fetch user details');
          }
        } catch (error) {
          // Handle any errors
          console.error('Error fetching user details:', error);
          return null;
        }

      }
    } catch (error) {
      console.log('Login failed:', error);
    }
  };



  const handleLogoutmicro = async () => {
    if (!initialized || !msalApp) {
      console.error('MSAL app is not initialized.');
      return;
    }

    try {
      await msalApp.logout();
      setAccount(null);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleRetrybutton = () => {
    setCode('');
    setOtpValues(['', '', '', '']);
    setSelectedOption(null);
  }

  return (

    <div className='login-in'>
      {isHamburger ? (<>
        <div className='d-flex flex-column align-items-center'>
          <div className="welcome-to-neoin">Welcome to Neoin</div>
          <div className="text-wrapper-2">Lets get started</div>
          <p className='mt-2 p-tag'>Unlock a world of possibilities, securely</p>
          <div className='center-container'>
            <div className="overlap-group-wrapper">
              <div className="overlap-group-2">
                {/* <img className="trailing-icon" src={trailing} alt='trailing' /> */}
                {/* <TextField
                  className="text-field-outline input-text"
                  id="neoinId"
                  name="neoinId"
                  label="Neoin ID"
                  variant="outlined"
                />
                <IconButton className="trailing-icon"><MicIcon /></IconButton> */}
                <TextField
                  inputRef={firstNameRef}
                  className="text-field-outline input-text neoinId"
                  id="neoinId"
                  name="neoinId"
                  label="Neoin iD"
                  variant="outlined"
                  required
                  value={Email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  enabled={micActive}
                />
                <IconButton
                  className={`trailing-icon ${micBlinkFirstName ? "blink" : ""}`}
                  onClick={() => handleMicClick(firstNameRef, setMicBlinkFirstName)}
                >
                  <MicIcon />
                </IconButton>
              </div>
            </div>
            <div className="overlap-group-wrapper">
              <div className="overlap-group-2">
                {/* <TextField
                  className="text-field-outline input-text"
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  variant="outlined"
                />
                <IconButton className="trailing-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton> */}
                <TextField
                  className="text-field-outline input-text"
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    // setFirstNameError(false);
                  }}
                />
                <IconButton className="trailing-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </div>
            </div>


            {/* <><div className='black-color-2'><Checkbox />Remember</div></>
             */}
            <div className='black-color-2'><Checkbox checked={rememberMe} onChange={handleRememberChange} />
              Remember
            </div>
            {/* <div className='black-color'>
              <label>
                <input type='checkbox' checked={rememberMe} onChange={handleRememberChange} />
                Remember
              </label>
            </div> */}
            <a href="#" className="input-text-3" onClick={handleClickForgotPassword}>Forgot Password?</a>

            {/* <div className='black-color'><Checkbox />I agree to the <span className='color-blue'>Terms & Conditions | Privacy Policy</span> </div>
             */}

            {/* <div className='black-color'>
              <label>
                <input type='checkbox' checked={termsAgreed} onChange={handleTermsChange} />
                I agree to the <span className='color-blue'>Terms & Conditions | Privacy Policy</span>
              </label>
            </div> */}
            <div className='black-color'>
              <Checkbox checked={termsAgreed} onChange={handleTermsChange} />
              I agree to the <span className='color-blue'>Terms & Conditions | Privacy Policy</span></div>
            <div className="element-contained-a-text-wrapper mb-20">
              <button className="custom-buttom element-contained-a-text" onClick={handleLogin}>
                <div className="text">
                  <div className="label">LOGIN</div>
                </div>
              </button>
            </div>
          </div>

          <div className="overlap-wrapper">
            <div className="overlap-3">
              <img className="line-2" src={line81} alt='line' />
              <div className="rectangle-2"></div>
              <div className="text-wrapper-5">Or</div>
            </div>
          </div>

          <div className="group-new mt-3">
            <div className="overlap-6 d-flex justify-content-center-login">
              <p className="scan-this-with-neoin">Scan this with Neoin app <br />to log in instantly</p>
              <p className="text-wrapper-6">Login with QR Code</p>
              {/* <img className="group-2" src={group4} alt='group' /> */}
              <div className="group-2">
                      {responseToken &&
                        <QrLogin uid={responseToken} />
                      }
                      {isExpired &&
                        <div style={{ color: 'red', fontSize: '13px' }}> QR Code expired. Please Reload.
                        </div>}
              </div>
            </div>
          </div>

          <div className="overlap-wrapper">
            <div className="overlap-3 mt-30">
              <img className="line-2" src={line81} alt='line' />
              <div className="rectangle-2"></div>
              <div className="text-wrapper-5">Or</div>
            </div>
          </div>

          <div className='login-with-account'>
            {/* <div className="d-flex justify-content-center">
              <div className="login-with-google">
                <div className="overlap-5">
                  <div className="login-with-google-2">Login with Google</div>
                  <img className="google-logo" src={google} alt='google' />
                </div>
              </div>
            </div> */}

            <div className="d-flex justify-content-center">
              <div className="login-with-google" onClick={handleGoogleLogin}>
                <GoogleLogin
                  clientId={clientId}
                  buttonText="Login with Google"
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={'single_host_origin'}
                  style={{ display: 'none' }} // Hide the actual Google login button

                />
              </div>
            </div>
            {/* 
            <div className="d-flex justify-content-center">
              <div className="div-wrapper mt-3">
                <div className="overlap-5">
                  <div className="login-with-google-2">Login with Microsoft</div>
                  <img className="google-logo" src={microsoft} alt='microsoft' />
                </div>
              </div>
            </div> */}


            <div onClick={handleLoginmicro} className="d-flex justify-content-center cursorshow">
              <div className="div-wrapper mt-3">
                <div className="overlap-5">
                  <div className="login-with-google-2">Login with Microsoft</div>
                  <img className="google-logo" src={microsoft} alt='microsoft' />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center cursorshow">
              <div className="login-with-facebook mt-3">
                <div className="overlap-4">
                  <img className="google-logo" src={linkedin} alt='linkedin' />
                  <div className="login-with-facebook-2">Login with Linkedin</div>
                </div>
              </div>
            </div>
          </div>
          <div className='create-neoin'>New to Neoin? <a href='https://neoin-onboard.azurewebsites.net' className="span">Create ID now</a></div>

          <div className="text-wrapper-3 text-center">Follow us on</div>
          <div className="social-media text-center">
            <div>
              <a href="link_to_frame">
                <img className="" src={frame} alt='frame' />
              </a>
              <a href="link_to_frame1">
                <img className="" src={frame1} alt='frame' />
              </a>
              <a href="link_to_frame2">
                <img className="" src={frame2} alt='frame' />
              </a>
              <a href="link_to_frame3">
                <img className="" src={frame3} alt='frame' />
              </a>
            </div>
          </div>

          <p className="element-by-neoin-design text-center">© 2024 by Neoin. Design &amp; Developed by Neoin</p>
        </div>

      </>) : (<>
        <div className="div container">
          <div className="row newcontainer">
            {/* <div className='col-5 group1'> */}
            <div className={isSmallScreen ? 'col-4 group1' : 'col-4 group1'}>
              <div className='d-flex flex-column align-items-center'>
                <div className="welcome-to-neoin">Welcome to Neoin</div>
                <div className="text-wrapper-2">Lets get started</div>
                <p className='mt-2 p-tag'>Unlock a world of possibilities, securely</p>
                <div>
                  <form>

                    <div className="overlap-group-wrapper">
                      <div className="overlap-group-2">
                        <TextField
                          inputRef={firstNameRef}
                          className="text-field-outline input-text neoinId"
                          id="neoinId"
                          name="neoinId"
                          label="Neoin iD"
                          variant="outlined"
                          required
                          value={Email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          enabled={micActive}
                        />
                        <IconButton
                          className={`trailing-icon ${micBlinkFirstName ? "blink" : ""}`}
                          onClick={() => handleMicClick(firstNameRef, setMicBlinkFirstName)}
                        >
                          <MicIcon />
                        </IconButton>
                      </div>
                    </div>

                    <div className="overlap-group-wrapper">
                      <div className="overlap-group-2">
                        <TextField
                          className="text-field-outline input-text"
                          id="password"
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          label="Password"
                          variant="outlined"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            // setFirstNameError(false);
                          }}
                        />
                        <IconButton className="trailing-icon" onClick={togglePasswordVisibility}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </div>
                    </div>

                    {/* <div className='black-color'><Checkbox />Remember</div> */}
                    <div className='black-color'><Checkbox checked={rememberMe} onChange={handleRememberChange} />
                      Remember
                    </div>

                    {/* <div className='black-color'>
                      <label>
                        <input type='checkbox' checked={rememberMe} onChange={handleRememberChange} />
                        Remember
                      </label>
                    </div> */}
                    <a href="#" className="input-text-3" onClick={handleClickForgotPassword}>Forgot Password?</a>
                    {/* <div className='black-color'><Checkbox />I agree to the <span className='color-blue'>Terms & Conditions | Privacy Policy</span> </div>
                    */}
                    {/* 
                    <div className='black-color'>
                      <label>
                        <input type='checkbox' checked={termsAgreed} onChange={handleTermsChange} />
                        I agree to the <span className='color-blue'>Terms & Conditions | Privacy Policy</span>
                      </label>
                    </div> */}
                    <div className='black-color'>
                      <Checkbox checked={termsAgreed} onChange={handleTermsChange} />
                      I agree to the <span className='color-blue'>Terms & Conditions | Privacy Policy</span>
                    </div>

                    <div className="element-contained-a-text-wrapper mb-10">
                      <button className="custom-buttom element-contained-a-text" onClick={handleLogin}>
                        <div className="text">
                          <div className="label">LOGIN</div>
                        </div>
                      </button>
                    </div>
                  </form></div>


                <div className="overlap-wrapper">
                  <div className="overlap-3">
                    <img className="line-2" src={line81} alt='line' />
                    <div className="rectangle-2"></div>
                    <div className="text-wrapper-5">Or</div>
                  </div>
                </div>

                <div className="group-new">
                  <div className="overlap-6">
                    <p className="scan-this-with-neoin">Scan this with Neoin app <br />to log in instantly</p>
                    <p className="text-wrapper-6">Login with QR Code</p>
                    {/* <img className="group-2" src={group4} alt='group' /> */}
                    <div className="group-2">
                      {responseToken &&
                        <QrLogin uid={responseToken} />
                      }
                      {isExpired &&
                        <div style={{ color: 'red', fontSize: '13px' }}> QR Code expired. Please Reload.
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={isSmallScreen ? 'col-4 group1' : 'col-4 group1'}>

              <div className="sep-line">
                <div className="overlap-2">
                  <img className="line" src={line8} alt='line' />
                  <div className="rectangle"></div>
                  <div className="text-wrapper-4">Or</div>
                </div>
              </div>

              <div className='login-with-account'>
                {/* <div className="d-flex justify-content-center">
                  <div className="login-with-google">
                    <div className="overlap-5">
                      <div className="login-with-google-2">Login with Google</div>
                      <img className="google-logo" src={google} alt='google' />
                    </div>
                  </div>
                </div> */}

                <div className="d-flex justify-content-center">
                  <div className="login-with-google" onClick={handleGoogleLogin}>
                    {/* <div className="overlap-5">
                      <div className="login-with-google-2">Login with Google</div>
                      <img className="google-logo" src={google} alt='google' />
                    </div> */}
                    <GoogleLogin
                      clientId={clientId}
                      buttonText="Login with Google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      cookiePolicy={'single_host_origin'}
                      style={{ display: 'none' }} // Hide the actual Google login button
                    />
                  </div>
                </div>

                {/* <div className="d-flex justify-content-center">
                  <div className="div-wrapper mt-3">
                    <div className="overlap-5">
                      <div className="login-with-google-2">Login with Microsoft</div>
                      <img className="google-logo" src={microsoft} alt='microsoft' />
                    </div>
                  </div>
                </div> */}


                <div onClick={handleLoginmicro} className="d-flex justify-content-center cursorshow">
                  <div className="div-wrapper mt-3">
                    <div className="overlap-5">
                      <div className="login-with-google-2">Login with Microsoft</div>
                      <img className="google-logo" src={microsoft} alt='microsoft' />
                    </div>
                  </div>
                </div>



                <div className="d-flex justify-content-center cursorshow">
                  <div className="login-with-facebook mt-3">
                    <div className="overlap-4">
                      <div className="login-with-facebook-2">Login with Linkedin</div>
                      <img className="google-logo" src={linkedin} alt='linkedin' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='create-neoin'>New to Neoin? <a href='https://neoin-onboard.azurewebsites.net' className="span">Create ID now</a></div>

              <div className="text-wrapper-3 text-center">Follow us on</div>
              <div className="social-media text-center">
                <div>
                  <a href="/comingsoon">
                    <img className="width-image" src={frame} alt='frame' />
                  </a>
                  <a href="https://sg.linkedin.com/company/neoinco">
                    <img className="width-image" src={frame1} alt='frame' />
                  </a>
                  <a href="/comingsoon">
                    <img className="width-image" src={frame2} alt='frame' />
                  </a>
                  <a href="https://www.youtube.com/@NEOIN-DALOCAL">
                    <img className="width-image" src={frame3} alt='frame' />
                  </a>
                </div>
              </div>

              <p className="element-by-neoin-design text-center">© 2024 by Neoin. Design &amp; Developed by Neoin</p>

            </div>
          </div>
          <div className="group-3">
            <div className="rectangle-3"></div>
            <div className="group-4">
              <div className="overlap-group-3">
                <img className="img-2" src={whatsnew} alt='whatsnew' />
                <div className="swiftsync-integratio">New Features and Updates</div>
                <div className="text-wrapper-7">Whats New!</div>
              </div>
            </div>
            <div className="group-5">
              <div className="overlap-8">
                <img className="img-2" src={help} alt='help' />
                <div className="swiftsync-integratio">Product Help Page</div>
                <div className="text-wrapper-7">Help</div>
              </div>
            </div>
            {/* <div className="group-6">
                        <div className="overlap-9">
                          <img className="img-2" src={website1} alt='website' />
                          <p className="swiftsync-integratio-2">Digital Identity &amp; Data Engine Empowering Ecosystem</p>
                          <div className="text-wrapper-8">Neoin</div>
                        </div>
                </div> */ }
            <div className="group-7">
              <div className="overlap-10">
                <img className="img-2" src={news} alt='news' />
                <p className="swiftsync-integratio-2">Digital Identity &amp; Data Engine Empowering Ecosystem</p>
                <div className="text-wrapper-8">News &amp; Events</div>
              </div>
            </div>
            <div className="card">
              <img className="IMG" src={img2} alt='img2' />
              <footer className="footer">
                <img className="video-tutorials" src={videotutorial} alt='video2' />
                <div className="labels">
                  <div className="title">Blogs</div>
                  <div className="subtitle">Product flow chart</div>
                </div>
              </footer>
            </div>

          </div>


        </div>
      </>)}

      {isForgotPassword &&
        <Dialog
          open={isForgotPassword}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="Forgot Password"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          BackProps={{
            style: { backgroundColor: 'red' }, // Set your desired background color here
          }}
        >
          <DialogContent className="dialogContainer">
            <DialogContentText className="dialogText">
              {FirstPageForgotPassword &&
                <div>
                  <p className="mt-10 onboard_header_align">Forgot your Password?</p>
                  <p className="paragraph-text-dialog">Enter your Neoin ID / Email to reset password</p>
                  <button type="button" className="lightbox__close" onClick={handlecloseDialog}>&times;</button>

                  <form className='mt-30'>
                    <TextField
                      className="text-field-outline input-text neoinId"
                      id="registered_neoinId"
                      name="registered_neoinId"
                      label="Enter registered Login ID"
                      variant="outlined"
                      required
                      value={registeredLoginId.email}
                      onChange={(e) => {
                        setvalidSubmitForgotPass(false);
                        setRegisteredLoginId({ ...registeredLoginId, email: e.target.value });;
                      }}
                    />
                    <div className="justify-content-flex-end">
                      <div className="next-button-container">
                        <button
                          className={`next-btn ${registeredLoginId.email?.trim() === '' || validSubmitForgotPass ? "disabled" : ""}`}
                          type="submit"
                          onClick={handleForgotPassword}
                          disabled={registeredLoginId.email?.trim().trim() === '' || validSubmitForgotPass}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              }
              {SecondPageForgotPassword &&
                <>
                  <p className="mt-10 onboard_header_align">Enter your  verification code</p>
                  <button type="button" className="lightbox__close" onClick={handlecloseDialog}>&times;</button>
                  <div className="otp-input-wrapper">
                    {[1, 2, 3, 4].map((_, index) => (
                      <input
                        key={index}
                        type="text" // Change type to "number" for numeric input
                        className="otp-input"
                        maxLength="1"
                        id={`otp${index + 1}`}
                        ref={(el) => (otpInputsRef.current[index] = el)}
                        value={otpValues[index]} // Set value from state
                        onChange={handleInput(index)}
                        disabled={minutes === 0 && seconds === 0}
                      />
                    ))}
                  </div>

                  <div className="justify-content-space-bw-login mt-20">
                    <div className="resent-otp">
                      Remaining Time: {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </div>

                    <div className="next-button-container" style={{ gap: "16px" }}>
                      <button className={`next-btn ${otpValues.some((value) => !value) || validVerifyCode ? "disabled" : ""}`}
                        type="submit" onClick={handleCodeSubmitPassword}
                        disabled={otpValues.some((value) => !value) || validVerifyCode}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                  <div className="resent-otp-design mt-20">
                    Didn't get the mail? <button className="button-style-height" onClick={handleForgotPassword}>RESEND</button>
                  </div>
                </>
              }
              {ThirdPageForgotPassword && (
                <div className='update-password justify-content-center-nw'>
                  <p className="mt-10 onboard_header_align">Update Password</p>
                  {/* <p className="paragraph-text-dialog text-center">Secure your neoin Id</p> */}
                  <div className='popup'>
                    <Typography
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                      className="secure-text" // Added a class for styling
                    >
                      <p>
                        Secure your neoin Id
                        <IconButton className="info-icon" style={{ width: '24px', height: '24px', margin: '2px' }}>
                          <InfoIcon />
                        </IconButton>
                      </p>
                    </Typography>
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: 'none',
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'bottom'
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <Typography className='pop-content'>
                        <p className='pop-content'> <span>&#10003;</span>  At least 8 characters <br />
                          <span>&#10003;</span>  At least one small letter<br />
                          <span>&#10003;</span>  At least one capital letter <br />
                          <span>&#10003;</span>  At least one number or symbol</p></Typography>
                    </Popover>
                  </div>

                  <button type="button" className="lightbox__close" onClick={handlecloseDialog}>&times;</button>

                  <div className='mt-30 justify-content-center-login-nw'>
                    <TextField
                      className="text-field-outline input-text" // Added centered-textfield class
                      id="new-password"
                      name="newPassword"
                      type={showNewPassword ? 'text' : 'password'}
                      label="New Password"
                      error={ShowPasswordError}
                      variant="outlined"
                      value={newPassword}
                      onChange={(e) => {
                        setShowPasswordError(false);
                        setNewPassword(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton className="trailing-icon" onClick={toggleNewPasswordVisibility}>
                              {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className='mt-20'>
                      <TextField
                        className="text-field-outline input-text centered-textfield" // Added centered-textfield class
                        id="confirm-password"
                        name="confirmPassword"
                        type={showConfirmPassword ? 'text' : 'password'}
                        label="Confirm Password"
                        error={ShowPasswordError}
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          setShowPasswordError(false);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton className="trailing-icon" onClick={toggleConfirmPasswordVisibility}>
                                {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <div className="justify-content-flex-end">
                      <div className="mt-10 next-button-container">
                        <button
                          className={`next-btn ${newPassword.trim() === '' || confirmPassword.trim() === '' ? "disabled" : ""}`}
                          type="submit"
                          onClick={handleUpdatePassword}
                          disabled={newPassword.trim() === '' || confirmPassword.trim() === ''}
                        >
                          UPDATE
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      }

      {isDialogBoxOpen &&
        <Dialog
          open={isDialogBoxOpen}
          // onClose={handleCloseVerifiedDialog}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="Enable 2FA (Two-factor Authentication)"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="dialogContainer">
            <DialogContentText className="dialogText">
              <div>
                <p className="mt-10 onboard_header_align">Enable 2FA (Two-factor Authentication)</p>
                <p className="paragraph-text">Choose the Method </p>
                <button type="button" className="lightbox__close" onClick={handlecloseDialog}>&times;</button>

                {TwoFactorAuthFirstPage && !TwoFactorAuthSecondPage && <>
                  <div className='justify-content-center-login'>
                    <Sheet
                      variant="outlined"
                      sx={{
                        boxShadow: 'sm',
                        borderRadius: 'sm',
                        marginBottom: 2,
                        p: 1,
                        width: 386,
                      }}
                    >
                      <RadioGroup name="tiers" value={selectedOption} onChange={handleOptionChange} sx={{ gap: 1, '& > div': { p: 1 } }}>
                        <FormControl>
                          <Radio className='text-align-left' overlay value="email" label="Email" />
                          <FormHelperText>Receive verification code via Email.</FormHelperText>
                        </FormControl>
                      </RadioGroup>
                    </Sheet>
                    <Sheet
                      variant="outlined"
                      sx={{
                        boxShadow: 'sm',
                        borderRadius: 'sm',
                        p: 1,
                        width: 386,
                      }}>
                      <RadioGroup name="tiers" value={selectedOption} onChange={handleOptionChange} sx={{ gap: 1, '& > div': { p: 1 } }}>
                        <FormControl>
                          <Radio className='text-align-left' overlay value="sms" label="Mobile SMS" />
                          <FormHelperText>Receive verification code via Mobile.</FormHelperText>
                        </FormControl>
                      </RadioGroup>
                    </Sheet>
                  </div>
                  <div className="justify-content-flex-end">
                    <div className="next-button-container">
                      {/* <Link to="/digital-identity" className="Link-white-text"> */}
                      {/* <button className={`skip-btn ${selectedOption !== null ? 'hidden' : ''}`}>
                        SKIP
                      </button> */}
                      {/* </Link> */}
                      <button
                        className={`next-btn ${selectedOption === null || validNextFor2FA ? "disabled" : ""}`}
                        type="submit"
                        onClick={handleAuthNextPage}
                        disabled={selectedOption === null || validNextFor2FA}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </>}

                {TwoFactorAuthSecondPage && !TwoFactorAuthFirstPage &&
                  <div>
                    {/* <h2 className="header_align">Enable 2FA (Two-factor Authentication)</h2>
                    <p className="paragraph-text">Enter the Code </p> */}
                    <div className=''>
                      <div>
                        <TextField
                          type={showCode ? "text" : "password"}
                          value={code}
                          onChange={handleCodeChange}
                          error={showCodeError}
                          helperText={showCodeError ? "Code is required" : ""}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={minutes === 0 && seconds === 0}
                          className={`text-field-outline input-text d-flex ${showCodeError ? "error-textfield" : ""}`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={toggleCodeVisibility}>
                                  {showCode ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errorMessage ? (
                          <div style={{ color: 'red', marginTop: '10px', minHeight: '20px' }}>
                            {errorMessage || '\u00A0'}
                          </div>
                        ) : (
                          // <div style={{ marginTop: '10px' }}>
                          // </div>
                          <></>
                        )}
                      </div>

                      <div className="justify-content-space-bw-login mt-20">
                        <div className="resent-otp">
                          Remaining Time: {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                        </div>

                        <div className="next-button-container" style={{ gap: "16px" }}>
                          <button className={`next-btn ${code.trim() === '' ? "disabled" : ""}`}
                            type="submit" onClick={handleCodeSubmit}
                            disabled={code.trim() === ''}>
                            Next
                          </button>
                        </div>
                      </div>
                      <div className="resent-otp-design mt-20">
                        Didn't get the mail? <button className="button-style-height" onClick={resendOTP}>RESEND</button>
                      </div>

                    </div>
                  </div>
                }
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      }

      {isVerifiedDialogOpen &&
        <>
          <Dialog
            open={isVerifiedDialogOpen}
            // onClose={handleCloseVerifiedDialog}
            // onClick={handleCloseVerifiedDialog}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="Enable 2FA (Two-factor Authentication)"
            aria-describedby="alert-dialog-description">
            <DialogContent className="dialogContainer">
              <DialogContentText className="dialogText">
                <div className='justify-content-center-1'>
                  <img
                    src={Tick}
                    alt="Verification"
                    className="dialogImage"
                  />
                  <p className='mt-10'>
                    Your Two-factor Authentication
                    is Successfull</p>

                  <button
                    className={`btn btn-primary`}
                    type="submit"
                    onClick={handleContinuetoDigital}
                  >
                    Continue
                  </button>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      }

      {isPageNotVerified &&
        <>
          <Dialog
            open={isPageNotVerified}
            // onClose={handleCloseVerifiedDialog}
            onClick={handleCloseVerifiedDialog}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="Enable 2FA (Two-factor Authentication)"
            aria-describedby="alert-dialog-description">
            <DialogContent className="dialogContainer">
              <DialogContentText className="dialogText">
                <div className='justify-content-center-1'>
                  <img
                    src={Warning}
                    alt="Verification"
                    className="dialogImage"
                  />
                  <p className='mt-10'>
                    Your Two-factor Authentication is not Successful </p>
                  <div className="mt-10 next-button-container">
                    <button
                      className={`next-btn`}
                      type="submit"
                      onClick={handleRetrybutton}
                    >
                      RETRY
                    </button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>

        </>
      }
      {showCaptcha && <Captcha />}
    </div>
  )
}

export default LoginNewTry
